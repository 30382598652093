import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CreditApplicationStatus } from '_gql'
import { useGetApplicationStatusForPolling } from '../../pages/gql/GetApplicationStatusForPolling.gql'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'
import AcceptingOfferPollingPageContent from './AcceptingOfferPollingPageContent'

const STATUS_POLLING_MAX_TIMEOUT = 120000
const POLLING_RATE = 3000

const calculateStatusTimeoutRemaining = (pollingForStatusSince) =>
  STATUS_POLLING_MAX_TIMEOUT - (new Date().getTime() - pollingForStatusSince)

const AcceptingOfferPollingPage = () => {
  /* make sure we have a token before we start polling */
  useApplicantTokenOnMount()
  const navigate = useNavigate()
  const { appId } = useParams()
  const [search] = useSearchParams()

  const pollingForStatusSince = parseInt(search?.get('pollingForStatusSince') as string)
  const [statusTimer, setStatusTimer] = useState(0)

  const {
    data,
    startPolling: startPollingForStatus,
    stopPolling: stopPollingForStatus,
  } = useGetApplicationStatusForPolling({
    fetchPolicy: 'no-cache',
    skip: !pollingForStatusSince || !appId,
    variables: { creditApplicationId: appId as string },
  })

  const isApprovedStatus = data?.creditApplication?.status === CreditApplicationStatus.APPROVED

  const handleSubmissionError = useCallback(() => {
    stopPollingForStatus()
    navigate(`/app/${appId}/section/submission-error`)
  }, [appId, navigate, stopPollingForStatus])

  useEffect(() => {
    if (pollingForStatusSince) {
      startPollingForStatus(POLLING_RATE)
      if (statusTimer >= calculateStatusTimeoutRemaining(pollingForStatusSince)) {
        handleSubmissionError()
      } else {
        setTimeout(() => setStatusTimer(statusTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [handleSubmissionError, pollingForStatusSince, startPollingForStatus, statusTimer])

  return (
    <AcceptingOfferPollingPageContent
      key={isApprovedStatus.toString()}
      isApprovedStatus={isApprovedStatus}
      stopPollingForStatus={() => stopPollingForStatus()}
    />
  )
}

export default AcceptingOfferPollingPage
