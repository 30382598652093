/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type * as _Types from '_gql'
const defaultOptions = {} as const
export type GetApplicationStatusForPolling_creditApplication_CreditApplication = {
  readonly __typename: 'CreditApplication'
  readonly salesforceCreditId: string
  readonly status: _Types.CreditApplicationStatus | null
}

export type GetApplicationStatusForPolling_Query = {
  readonly __typename: 'Query'
  readonly creditApplication: GetApplicationStatusForPolling_creditApplication_CreditApplication | null
}

export type GetApplicationStatusForPollingVariables = _Types.Exact<{
  creditApplicationId: _Types.Scalars['ID']['input']
}>

export type GetApplicationStatusForPolling = GetApplicationStatusForPolling_Query

export const GetApplicationStatusForPollingDocument = gql`
  query GetApplicationStatusForPolling($creditApplicationId: ID!) {
    creditApplication(creditApplicationId: $creditApplicationId) {
      salesforceCreditId
      status
    }
  }
`

/**
 * __useGetApplicationStatusForPolling__
 *
 * To run a query within a React component, call `useGetApplicationStatusForPolling` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationStatusForPolling` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationStatusForPolling({
 *   variables: {
 *      creditApplicationId: // value for 'creditApplicationId'
 *   },
 * });
 */
export function useGetApplicationStatusForPolling(
  baseOptions: Apollo.QueryHookOptions<GetApplicationStatusForPolling, GetApplicationStatusForPollingVariables> &
    ({ variables: GetApplicationStatusForPollingVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApplicationStatusForPolling, GetApplicationStatusForPollingVariables>(
    GetApplicationStatusForPollingDocument,
    options,
  )
}
export function useGetApplicationStatusForPollingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationStatusForPolling, GetApplicationStatusForPollingVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApplicationStatusForPolling, GetApplicationStatusForPollingVariables>(
    GetApplicationStatusForPollingDocument,
    options,
  )
}
export function useGetApplicationStatusForPollingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApplicationStatusForPolling,
    GetApplicationStatusForPollingVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetApplicationStatusForPolling, GetApplicationStatusForPollingVariables>(
    GetApplicationStatusForPollingDocument,
    options,
  )
}
export type GetApplicationStatusForPollingHookResult = ReturnType<typeof useGetApplicationStatusForPolling>
export type GetApplicationStatusForPollingLazyQueryHookResult = ReturnType<
  typeof useGetApplicationStatusForPollingLazyQuery
>
export type GetApplicationStatusForPollingSuspenseQueryHookResult = ReturnType<
  typeof useGetApplicationStatusForPollingSuspenseQuery
>
export type GetApplicationStatusForPollingQueryResult = Apollo.QueryResult<
  GetApplicationStatusForPolling,
  GetApplicationStatusForPollingVariables
>
