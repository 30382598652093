import { func, object } from 'prop-types'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import React from 'react'
import { css } from '@emotion/core'
import Tile from '@divvy-web/skylab.tile'
import { StatusButtons } from '../../../components'
import { dashboardTitleCss, statusButtonsCss } from '../dashboardStyles'
import DashboardItemContent from './DashboardItemContent'

const DashboardItem = ({ itemContent }) => {
  const {
    businessName,
    isApproved,
    isPersonaInq,
    onPrimaryClick,
    onSecondaryClick,
    primaryButtonText,
    primaryLoading,
    primaryPath,
    salesforceCreditId,
    secondaryButtonText,
    secondaryLoading,
    secondaryPath,
    status,
  } = itemContent

  const makeTestId = useMakeTestId('ApplicationStatus', salesforceCreditId)

  return (
    <section className='fs-unmask'>
      {businessName && (
        <div
          className='dashboard-business-name'
          css={dashboardTitleCss}
          data-testid={`${salesforceCreditId}-businessName`}
        >
          {businessName}
        </div>
      )}
      <Tile
        className='app-tile'
        css={(theme) => applicationTileCss({ isApproved }, theme)}
        dataTestId={isPersonaInq ? makeTestId(`${status}-persona`) : makeTestId(`${status}`)}
      >
        <div className='tile-wrapper'>
          <DashboardItemContent itemContent={itemContent} />
          <div
            className='status-button-container'
            css={(theme) => statusButtonsCss(theme)}
          >
            {(primaryButtonText || secondaryButtonText) && (
              <StatusButtons
                appId={salesforceCreditId}
                hidePrimary={!primaryButtonText}
                hideSecondary={!secondaryButtonText}
                primaryLoading={primaryLoading}
                primaryPath={primaryPath}
                primaryText={primaryButtonText}
                secondaryLoading={secondaryLoading}
                secondaryPath={secondaryPath}
                secondaryText={secondaryButtonText}
                onPrimaryClick={onPrimaryClick}
                onSecondaryClick={onSecondaryClick}
              />
            )}
          </div>
        </div>
      </Tile>
    </section>
  )
}

DashboardItem.propTypes = {
  itemContent: object.isRequired,
  setShowModal: func,
}

export default DashboardItem

export const applicationTileCss = ({ isApproved }, { mq }) => css`
  .Tile-content {
    background-image: ${isApproved && 'url("https://app.divvy.co/assets/backgrounds/confetti_background.png")'};
    padding: var(--tri-space-300) var(--tri-space-300) var(--tri-space-450) var(--tri-space-300);
    ${mq.xSmallMaxWidth({ backgroundSize: '400%', padding: 'var(--tri-space-300)' })}
  }

  .status-tile-content {
    background-color: white;
    width: fit-content;
    ${mq.xSmallMaxWidth({ width: '100%' })}
  }

  .tile-wrapper {
    display: flex;
    flex-direction: column;
  }

  .verify-persona-button > .BasicButton {
    ${mq.xSmallMaxWidth({ width: '240px' })}
  }

  .verify-persona-button {
    padding-top: var(--tri-space-300);
    ${mq.xSmallMaxWidth({
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    })};
  }

  .status-button-container {
    margin-left: var(--tri-space-2200);
    ${mq.xSmallMaxWidth({ justifyContent: 'center', marginLeft: 0 })}
  }

  .status-button-container > .StatusButtons {
    ${mq.xSmallMaxWidth({
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 'var(--tri-space-200)',
      justifyContent: 'center',
      paddingTop: 'var(--tri-space-200)',
    })}
  }

  .status-button-container .secondary-button {
    ${mq.xSmallMaxWidth({ marginLeft: 0 })}
  }
`
